.about{
  border-bottom: 1px solid #3b675e;
  .about-scroll__spy{
    position: fixed;
    right: 10px;
    top: 50%;
    z-index: 2;
    .links{
      position: relative;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      a{
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #f3f3f3;
        border: 1px solid #f3f3f3;
        cursor: pointer;
      }
      a.active{
        background-color: #024638;
      }
      hr{
        margin: 0px;
        border-right: 2px solid #f3f3f3;
        height: calc(100% - 2px);
        position: absolute;
        top: 0px;
        left: calc(50% - 1px);
        z-index: -1;
      }
    }
  }
  &-header{
    background-color: #024638;
    align-items: flex-end;
    margin-bottom: 70px;
    .header_container--desktop{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      img{
        width: 537px;
        height: 463px;
        position: relative;
        bottom: -100px;
      }
      svg{
        position: relative;
        left: 100px;
        width: 400px;
        height: 175px;
      }
      @media (min-width: 1600px){
        img{
          width: 80%;
          height: auto;
          position: relative;
          bottom: -100px;
          max-width: 900px;
        }
        svg{
          position: relative;
          left: 100px;
          width: 700px;
          height: auto;
        }
      }
      @media (max-width: 2300px){
        img{
          max-width: 40%;
          height: auto;
        }
        svg{
          max-width: 70%;
        }
      }
      @media (max-width: 2000px){
        svg{
          position: relative;
          left: 100px;
          width: 400px;
          height: 175px;
          max-width: 100%;
        }
      }
      @media (max-width: 1100px){
        svg{
          position: relative;
          left: 30px;
          width: 400px;
        }
      }
      @media (max-width: 1000px){
        img{
          width: 500px;
          height: auto;
        }
        svg{
          left: 30px;
          width: 90%;
          max-width: 300px;
        }
      }
      @media (max-width: 850px){
        img{
          width: 400px;
          height: auto;
        }
        svg{
          width: 100%;
          max-width: 250px;
          height: auto;
        }
      }
      @media (max-width: 700px){
        img{
          width: 230px;
          height: auto;
          top: 60px;
        }
        svg{
          width: 320px;
          height: auto;
        }
      }
      @media (max-width: 600px){
        img{
          width: 175px;
          height: auto;
          top: 60px;
        }
        svg{
          width: 200px;
          height: auto;
        }
      }
      @media (max-width: 500px){
        img{
          width: 180px;
          height: auto;
          top: 40px;
        }
        svg{
          width: 100%;
          max-width: 150px;
          padding-right: 10px;
          height: auto;
          left: 0px;
        }
      }
    }
    @media (max-width: 850px){
      margin-bottom: 70px;
    }
  }
  .why{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .why-container{
      display: flex;
      max-width: 760px;
      margin: 0px auto;
      align-items: flex-start;
      .why-title {
        flex: 1;
        padding: 0px 10px;
        h2{
          font-size: 50px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: #024638;
          margin: 0px;
        }
      }
      .why-info {
        flex: 1;
        padding: 0px 10px;
        p{
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.31;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.5);
          margin: 0px;
        }
      }
      @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        padding: 0px 10px;
        .why-title {
          margin-bottom: 17px;
          h2{
            text-align: center;
            font-size: 24px;
          }
        }
        .why-info{
          margin-top: 20px;
          text-align: center;
          font-size: 14px;
        }
      }
    }
    .why-arrow{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      hr{
        margin: 0px;
        width: 0px;
        height: 80px;
        border-top: none;
        border-right: 1px dashed #024638;
        margin: 10px 0px;
      }
      svg{
        cursor: pointer;
      }
      @media (max-width: 600px){
        flex-direction: column-reverse;
      }
    }
  }
  .justo{
    .justo-title{
      font-size: 36px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 46px;
      @media (max-width: 600px) {
        font-size: 20px;
        margin-bottom: 32px;
      }
    }
    .justo-items{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .justo-items__item{
        width: 280px;
        position: relative;
        margin-bottom: 50px;
        img{
          width: 240px;
          height: 240px;
        }
        .justo-items__item-title{
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #024638;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 180px;
          height: 51px;
          box-shadow: 0 20px 30px 0 rgba(97, 97, 97, 0.25);
          background-color: #f3f3f3;
          text-align: center;
          position: absolute;
          top: 20px;
          right: 20px;
          z-index: 1;
        }
        .justo-items__item-description{
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.21;
          letter-spacing: normal;
          text-align: center;
          color: rgba(0, 0, 0, 0.5);
          width: 240px;
          margin-right: auto;
          margin-top: 10px;
        }
        @media (max-width: 600px) {
          margin-bottom: 37px;
          .justo-items__item-title{
            font-size: 16px;
          }
          .justo-items__item-description{
            font-size: 14px;
          }
        }
      }
      @media (max-width: 900px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      @media (max-width: 600px) {
        align-items: normal; 
        .justo-item__container:nth-child(odd){
          display: flex;
          justify-content: flex-start;
          .justo-items__item{
            .justo-items__item-description{
              text-align: left;
              padding-left: 22px;
            }
          }
        }
        .justo-item__container:nth-child(even){
          display: flex;
          justify-content: flex-end;
          .justo-items__item{
            width: 240px;
            .justo-items__item-title{
              left: -20px;
              right: 0px;
            }
            .justo-items__item-description{
              text-align: right;
              padding-right: 22px;
            }
          }
        }
      }
    }
  }
  .promises{
    margin-bottom: 10px;
    .promises-title {
      margin-bottom: 10px;
      h2{
        font-size: 50px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #024638;
      }
    }
    .promise{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
      .promise-info{
        width: 400px;
        height: 400px;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .promise-info__text{
          position: relative;
          left: 1px;
          background-color: #fff;
          h4{
            font-size: 36px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #024638;
            margin: 0px;
          }
          p{
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0.6);
            margin: 0px;
            padding-bottom: 12px;
          }
        }
      }
      .promise-image{
        width: 400px;
        border-left: 1px dashed #024638;
        img{
          width: 100%;
        }
      }
    }
    .promise:nth-child(odd){
      flex-direction: row-reverse;
      .promise-info{
        text-align: left;
        border-left: 1px dashed #024638;
        .promise-info__text{
          left: -1px;
        }
      }
      .promise-image{
        border-left: none;
      }
    }
    hr{
      display: none;
    }
    @media (max-width: 600px) {
      .promises-title h2{
        font-size: 24px;
      }
      .promise,
      .promise:nth-child(odd) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 90%;
        margin: auto;
        .promise-image{
          border-left: none;
          height: auto;
          width: 90%;
          max-width: 240px;
          margin: auto;
        }
        .promise-info{
          border-left: none;
          height: auto;
          text-align: center;
          margin: 15px 0px;
          width: auto;
          .promise-info__text{
            h4{
              font-size: 18px;
            }
            p{
              font-size: 14px;
            }
            h4, p{
              text-align: center;
            }
          }
        }
      }
      hr{
        display: block;
        margin: 0px auto;
        width: 0px;
        height: 80px;
        border-top: none;
        border-right: 1px dashed #024638;
      }
    }
  }
  .are{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
      max-width: 461px;
      width: calc(100% - 46px);
      font-size: 32px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #024638;
      margin: 0px 12px;
      margin-bottom: 16px;
    }
    p{
      max-width: 620px;
      width: calc(100% - 46px);
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.4px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      margin: 0px 12px;
      margin-bottom: 40px;
    }
    @media (max-width: 600px) {
      h2{
        font-size: 24px;
      }
      p{
        font-size: 14px;
      }
    }
  }
  .says{
    background-color: #024638;
    padding-top: 79px;
    padding-bottom: 100px;
    padding-right: 40px;
    padding-left: 102px;
    display: flex;
    @media (max-width: 1070px) {
      padding: 40px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .says-container{
      max-width: 1140px;
      margin: auto;
      display: flex;
      .says-title{
        width: calc(100% - 766px);
        min-width: 160px;
        p{
          font-size: 36px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.11;
          letter-spacing: normal;
          color: #ffffff;
          margin-bottom: 0px;
        }
        @media (max-width: 1070px) {
          width: 100%;
          margin-bottom: 40px;
          p{
            text-align: center;
          }   
        }
        @media (max-width: 600px) {
          p{
            font-size: 24px;
          }
        }
      }
      .says-items{
        display: flex;
        flex: 1;
        justify-content: space-around;
        flex-wrap: wrap;   
        .say{
          width: 214px;
          margin: 0px 20px;
          margin-bottom: 10px;
          height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .say-description{
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.21;
            letter-spacing: normal;
            color: #ffffff;
            margin-bottom: 0px;
          }
          .say-who{
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #ffffff;
            margin-bottom: 0px;
          }
          @media (max-width: 990px) {
            margin-bottom: 68px;   
            height: auto;
            .say-description{
              margin-bottom: 30px;
            }
          }
          @media (max-width: 600px) {
            .say-description{
              font-size: 14px;
            }
            .say-who{
              font-size: 14px;
            }
          }
        }
        @media (max-width: 850px){
          flex-direction: column;
        }
      }
      @media (max-width: 1070px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .arrow-return{
      display: none;
      .arrow-return__icon{
        border-radius: 50%;
        width: 52px;
        height: 52px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #ffffff;
        margin: auto;
        margin-bottom: 10px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          transform: rotate(180deg);
        }
      }
      .arrow-return__message{
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
      @media (max-width: 600px) {
        display: block;
      }
    }
  }
}