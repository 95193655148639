h3 {
  text-transform: uppercase;
}

a {
  color: $turquoise;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.link--styled {
    font-size: 0.8rem;
    color: $body-color;
    text-decoration: underline;
  }
  &.link--clean {
    color: $body-color;
    &:hover {
      text-decoration: none;
    }
  }
}
