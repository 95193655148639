#order-whatsapp__help{
    position: fixed;
    left: 10%;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    .whatsapp-ballon{
        width: 262px;
        height: 168px;
        border-radius: 10px;
        box-shadow: 3px 6px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        padding: 16px;
        padding-top: 4px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        .whatsapp-ballon__closed{
            width: 10px;
            height: 10px;
            margin-bottom: 16px;
            cursor: pointer;
        }
        .whatsapp-ballon__info{
            width: 100%;
            height: 73px;
            border-radius: 15px;
            background-color: #e5e5e5;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
            text-align: left;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;
            padding: 8px;
        }
        .whatsapp-ballon__action{
            width: 100%;
            height: 28px;
            border-radius: 8px;
            background-color: #56d062;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
    .whatsapp-brand{
        margin-top: 24px;
    }
    @media (max-width: 990px) {
        bottom: 100px;
    }
    @media (max-width: 700px) {
        right: 10%;
        left: auto;
        align-items: flex-end;
    }
}