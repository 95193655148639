#product-list-image {
  color: #313131;
  background-position: top;
  background-repeat: repeat-x;
  background-size: cover;
  @include media-breakpoint-down(sm) {
    padding: 17 * $global-padding 0 0;
    margin-top: -1 * $global-margin;
  }
  @include media-breakpoint-up(md) {
    padding: 20 * $global-padding 0 0;
  }
}
.no-image-list {
  margin-top: 2rem;
}
.list-filters {
  .filters-section-container {
    margin-top: 2rem;
    .filter-section {
      margin-top: 1rem;
    }
    li {
      border: 0;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
  list-style: none;
  padding: 30px 0;
  .active {
    border-left: 4px solid #2d4838;
    font-weight: bold;
  }
  a {
    color: #2d4838;
  }
  li {
    border-left: 4px solid lightgrey;
  }
  &-super {
    font-size: 20px;
    a {
      padding: 0 15px;
    }
  }
  &-head {
    font-size: 18px;
    a {
      padding: 0 10px;
    }
  }
  &-child {
    font-size: 16px;
    padding-left: 20px;
    a {
      padding: 0 6px;
    }
  }
}
.list-content {
  padding-top: 30px;
}
.product-list-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: flex-start;
  @media (max-width: 768px) {
    justify-content: center;
  }
  .home_featured_list_item {
    margin: 5px;
    @media (max-width: 426px) {
      margin: 5px auto;
    }
  }
}
.product-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-container{
  margin-top: 50px;
  @media (max-width: 425px) {
    margin-top: 1px;
  }
}
.button-outline {
  padding: 13px 0;
  height: auto;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  max-width: none;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-transform: none;
  display: none;
  text-decoration: none;
  transition-duration: 0.4s;
  @media screen and (max-width: 500px) {
     display:inline-block;
  }
}
.button-outline-come-back {
  color: #2d4838;
  background: white;
  border: 0.5px solid #2d4838;
}
.button-outline-come-back:hover {
  color: white;
  background: #2d4838;
  text-decoration: none;
}
.product-list {
  text-align: center;
  margin-bottom: $global-margin * 2;
  box-shadow: 2px 2px 2px 0 rgba(210, 210, 210, 0.5);
  border-radius: 4px;
  padding: 16px;
  img {
    max-width: 100%;
  }
  &-item-name {
    display: block;
    font-size: 16px;
    font-weight: bold;
  }
  .panel-footer {
    color: $justo-blue;
    letter-spacing: 1.6px;
    font-size: 20px;
    margin: 10px 0 10px;
    text-align: left;
  }
  .text-center {
    position: relative;
  }
  &__sale {
    svg {
      position: absolute;
      left: 0;
      top: 0;
    }
    &__text {
      position: absolute;
      top: 0.5rem;
      left: 0.15rem;
      color: $white;
      font-size: 0.8rem;
      text-transform: uppercase;
      transform: rotate(-45deg);
      font-weight: 500;
    }
  }
  &__header {
    @include media-breakpoint-down(sm) {
      padding: $global-padding * 2 0;
    }
    @media (max-width: 370px) {
      padding-top: 5rem;
    }
    h1 {
      font-size: 2.5rem;
      color: $body-color;
      padding-bottom: 0;
      text-align: left;
    }
  }
}
