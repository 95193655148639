.product-search__total{
  font-weight: bold;
  margin: 0px;
  padding: 0px 15px;
  span{
    color: var(--gray);
  }
  @media (min-width: 768px) {
    margin-top: 10px;
  }
  @media (min-width: 1024px) {
    margin-top: 30px;
  }
}
.product-search-container {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  justify-content: center;
  @media (min-width: 768px) {
    margin: 20px 0;
    justify-content: normal;
  }
  @media (min-width: 1024px) {
    margin: 40px 0;
  }
  .home_featured_list_item {
    margin: 5px;
    @media (max-width: 426px) {
      margin: 5px auto;
    }
  }
}