.general-header,
.checkout-header {
  box-shadow: 0 2px 4px 0 rgba(166, 166, 166, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  .referrals-alert {
    width: 100%;
    min-height: 36px;
    color: inherit;
    background-color: #fbdc74;
    display: flex;
    &:hover {
      text-decoration: none;
    }
    .referrals-alert__container {
      max-width: 1140px;
      width: 100%;
      margin: auto;
      padding: 8px 14px;
      display: flex;
      align-items: center;
      height: auto;
      svg {
        margin-right: 6px;
      }
      & > div {
        flex: 1;
        * {
          margin: 0;
          padding: 0;
        }
      }
      @media screen and (max-width: 1100px) {
        padding: 0px 8px;
      }
    }
  }
}

.general-header {
  & ~ main,
  & ~ div main {
    margin-top: 150px;
    min-height: calc(100vh - 466px);
    @media (max-width: 1130px) {
      margin-top: 200px;
      min-height: calc(100vh - 674px);
    }
  }

  & ~ div,
  & ~ main {
    .alert {
      top: 150px;
      position: fixed;
      width: 100%;
      left: 0px;
      text-align: center;
      p {
        margin-bottom: 0px;
      }
      @media (max-width: 990px) {
        top: 165px;
      }
    }
  }

  & ~ #mobileAlert {
    top: 170px;
    position: fixed;
    display: none;
    width: 100%;
    left: 0px;
    text-align: center;
    p {
      margin-bottom: 0px;
    }
    @media (min-width: 990px) {
      display: none !important;
    }
    @media (max-width: 990px) {
      top: 142px;
      text-align: center;
    }
  }
}

.checkout-header {
  position: sticky;
  top: 0px;
  margin-bottom: 16px;
  
  & ~ div .alert,
  & ~ main .alert,
  & ~ #mobileAlert {
    margin-top: 48px;
    position: fixed;
    width: 100%;
    left: 0px;
    text-align: center;
    z-index: 100;
    p {
      margin-bottom: 0px;
    }
  }
}
