.privacyTitle {
    color: $justo-green;
    text-align: justify;
    font-weight: bold;
    font-size: 26px;
    text-align: left;
  }

.privacySubTitle {
    color: $justo-green;
    text-align: justify;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
}

.privacyInfo {
    text-align: justify;
    font-weight: normal;
    font-size: 16px;
    font-style: italic;
}
