.spinner-button{
  display: none;
}
[data-spinner-disabled]:disabled,
[data-spinner-disabled],
[data-spinner-disabled]:hover{
  border-radius: 12px !important;
  background-color: #f2f2f2 !important;
  cursor: no-drop !important;
  border: none !important;
  *{
    display: none;
  }
  .spinner-button{
    animation: spinner-rotate 1.568s linear infinite;
    height: calc(100% - 16px);
    margin: auto 0px;
    display: block;
    pointer-events: none;
    *{
      display: block;
    }
  }
  .spinner-button,
  .spinner-button__loader{
    transform-origin: 50% 50%;
  }
  .spinner-button__loader {
    animation: spinner-offset 5332ms step-end infinite;
  }
  .spinner-button__loader-path{
    fill: none;
    stroke-width: 4%;
    stroke-dasharray: 56, 200;
    stroke-dashoffset: 0;
    animation: spinner-dash 1333ms ease-in-out infinite;
    stroke-linecap: round;
    stroke: #828282;
  }
  @keyframes spinner-rotate{
    to {
        transform: rotate(1turn);
    }
  }
  @keyframes spinner-offset{
    25% {
        transform: rotate(270deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(90deg);
    }
    to {
        transform: rotate(0);
    }
  }
  @keyframes spinner-dash{
    0% {
        stroke-dasharray: 0, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 56, 200;
        stroke-dashoffset: 0;
    }
    to {
        stroke-dasharray: 0, 200;
        stroke-dashoffset: -56;
    }
  }
}
