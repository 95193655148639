.faq{
  padding-bottom: 30px;
  min-height: calc(100vh - 400px);
  .faq-title{
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d4838;
    margin: 26px 0px;
    text-align: center;
    @media (max-width: 600px){
      font-size: 26px;
    }
  }
  .faq-container{
    width: calc(100% - 44px);
    max-width: 700px;
    margin: 0px auto;
    .faq-question{
      .faq-question__title{
        cursor: pointer;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
          font-size: 18px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2d4838;
          max-width: calc(100% - 30px);
        }
        svg{
          width: 15px;
          height: 15px;
          transition: transform .3s;
          path{
            fill: #2d4838;
          }
        }
        svg.rotated{
          transform: rotate(180deg);
        }
        @media (max-width: 600px){
          p{
            font-size: 18px;
          }
          svg{
            width: 15px;
            height: 15px;
          }
        }
      }
      .faq-question__content{
        display: none;
        p{
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #333333;
          span{
            font-weight: bold;
          }
          a{
            color: #333333; 
          }
          a.here{
            text-decoration: underline;
            color: #333333; 
          }
        }
        @media (max-width: 600px){
          p{
            font-size: 16px;
          }
        }
      }
    }
  }
}