.btn {
  transition: 0.3s;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 0px 10px;
  cursor: pointer;
  text-decoration: none;
  &-primary{
    color: $white;
    background-color: $justo-green;
    &:not(:disabled):not(.disabled):hover,
    &.disabled,
    &:disabled,
    &:hover,
    &:active,
    &:focus {
      background-color: $justo-green !important;
      color: $white;
    }
  }
  &-secondary {
    border: 1px solid $justo-green;
    background-color: $white;
    color: $justo-green;
    &:not(:disabled):not(.disabled):hover,
    &.disabled,
    &:disabled,
    &:hover,
    &:active,
    &:focus {
      border: 1px solid $justo-green;
      background-color: #ececec !important;
      color: $justo-green;
    }
  }
  &-terciary{
    color: $body-color;
    background-color: #fbdc74;
    border-radius: 12px;
    &:not(:disabled):not(.disabled):hover,
    &.disabled,
    &:disabled,
    &:hover,
    &:active,
    &:focus {
      background-color: #fbdc74 !important;
      color: $body-color;
    }
  }
  &__180{
    min-width: 180px;
  }
  &.danger {
    border: 1px solid $red;
    background-color: transparent;
    color: $red;
    &:not(:disabled):not(.disabled):hover,
    &.disabled,
    &:disabled,
    &:hover,
    &:active,
    &:focus {
      background-color: $dark-red !important;
      color: $white;
    }
  }
  &.gray {
    background-color: $gray;
    border: 1px solid $gray;
    color: $white;
    &:not(:disabled):not(.disabled):hover,
    &.disabled,
    &:disabled,
    &:hover,
    &:active,
    &:focus {
      background-color: $darken-gray !important;
    }
  }
  &.btn-link {
    padding: 0;
    vertical-align: baseline;
    color: $gray;
    text-transform: none;
  }
  &.social-btn {
    width: 100%;
    max-width: 320px;
    height: 40px;
    padding: 0px;
    @media (max-width: 400px) {
      width: 44px;
      height: 44px;
      border-radius: 6px;
    }
    &.google {
      .google-content{
        background: #F14336;
        padding: 0px;
        height: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        &:hover {
          background: darken(#F14336, 10%);
        }
        p{
          font-size: 16px;
          margin-bottom: 0px;
          @media (max-width: 400px) {
            display: none;
          }
        }
        svg{
          width: 14px;
          height: 14px;
          margin-right: 10px;
          &.icon-mobile{
            display: none;
          }
          @media (max-width: 400px) {
            margin-right: 0px;
            display: none;
            &.icon-mobile{
              display: block;
            }
          }
        }
      }
    }
    &.facebook {
      .facebook-content{
        background: #4267b2;
        padding: 0px;
        height: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        p{
          font-size: 16px;
          margin-bottom: 0px;
          font-weight: normal;
          @media (max-width: 400px) {
            display: none;
          }
        }
        svg{
          width: 14px;
          height: 14px;
          margin-right: 10px;
          &.icon-mobile{
            display: none;
          }
          @media (max-width: 400px) {
            margin-right: 0px;
            display: none;
            &.icon-mobile{
              display: block;
            }
          }
        }
      }
    }
    &.apple {
      .apple-content{
        background: #000000;
        padding: 0px;
        height: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        p{
          font-family: "SF Pro Display";
          margin-bottom: 0px;
          font-size: 16px;
          &.apple-content__icon{
            margin-right: 10px;
            font-size: 20px;
          }
          @media (max-width: 400px) {
            &.apple-content__text{
              display: none;
            }
            &.apple-content__icon{
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.btn.disabled,
.btn:disabled{
  cursor: not-allowed;
}
.social-buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 400px) {
    flex-direction: row;
    .btn.social-btn{
      margin: 0px 5px;
    }
  }
}
