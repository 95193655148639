body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.fonts-loaded {
  * {
    font-family: "Lato";
  }
}
.container {
  flex: 1 0 auto;
}
*:focus {
  outline: none !important;
}
*[disabled],
*[readonly],
*:disabled {
  cursor: no-drop;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
a {
  color: $justo-green;
}
.checkbox {
  display: block;
  cursor: pointer;
  user-select: none;
  margin: 0px;
  display: flex;
  justify-content: center;
  margin: 6px 0px;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input[type="checkbox"]:checked ~ .checkmark {
    background-color: $justo-green;
    border: solid 2px $justo-green;
    svg {
      display: block;
    }
  }

  .checkmark {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: solid 2px #d3d1d1;
    display: block;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
      display: none;
    }
  }

  &:hover {
    .checkmark {
      background-color: #d3d1d1;
    }
  }

  .label {
    &:before {
      display: none;
    }
    flex: 1;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-left: 8px;
  }
}
.card-deck .card ul.icons li a svg:hover path {
  fill: #2d4838;
}
.card-errors {
  color: $red;
  margin: 10px 0px;
}
.alert {
  z-index: 5;
  top: 0;
}
.input-error {
  margin: 4px 0px;
  color: $dark-red;
  width: 100%;
  font-size: 14px;
}

.message_error {
  display: none;
  border-radius: 5px;
  border: solid 0.5px #d7384a;
  background-color: #eb5757;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #fafafa !important;
  padding: 16px;
  margin: 0px;
  position: fixed;
  left: 33px;
  bottom: 33px;
  z-index: 10000000;
  &--show {
    display: block;
  }
  @media (max-width: 990px) {
    bottom: 20px;
  }
  @media (max-width: 500px) {
    width: calc(100% - 30px);
    left: 15px;
    right: 15px;
    text-align: center;
  }
}
input.hide-clear[type="search"]::-ms-clear,
input.hide-clear[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input.hide-clear[type="search"]::-webkit-search-decoration,
input.hide-clear[type="search"]::-webkit-search-cancel-button,
input.hide-clear[type="search"]::-webkit-search-results-button,
input.hide-clear[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.styleguide {
  &__nav {
    a {
      display: block;
    }
    margin-bottom: $global-margin * 2;
    &.fixed {
      position: fixed;
      top: 20px;
      @include media-breakpoint-down(sm) {
        position: relative;
      }
    }
  }
  &__section {
    border: 2px solid $light-gray;
    padding: $global-padding;
    border-radius: 0px;
    margin-bottom: $global-margin;
    p {
      margin-bottom: 0;
    }
    [contenteditable="true"] {
      &:focus {
        outline: none;
        opacity: 0.9;
      }
    }
  }
  &__tittle {
    background-color: $light-gray;
    margin-bottom: 0;
    padding: $global-padding;
    p {
      margin-bottom: 0;
      padding-top: $global-padding;
    }
    h3 {
      margin-bottom: 0;
      padding-top: 0;
    }
  }
  &__sub-tittle {
    background-color: $light-gray;
    padding: $global-padding;
    margin-bottom: 0;
    margin-top: -$global-padding;
    p {
      margin-bottom: 0;
    }
  }
  &__content {
    &__block {
      padding-bottom: $global-padding * 3;
      border-top: 1px solid $gray;
      &__bold {
        font-weight: 700;
      }
      &__light {
        font-weight: 300;
      }
      &__color {
        text-align: center;
        div {
          width: 100px;
          height: 100px;
          border-radius: 150px;
          margin: 0 auto $global-margin;
        }
        &--body-color {
          background-color: $body-color;
        }
        &--white {
          background-color: $white;
          border: 1px solid $gray;
        }
        &--light-gray {
          background-color: $light-gray;
        }
        &--gray {
          background-color: $gray;
        }
        &--darken-gray {
          background-color: $darken-gray;
        }
        &--skull-gray {
          background-color: $skull-gray;
        }
        &--blue {
          background-color: $blue;
        }
        &--darken-blue {
          background-color: $darken-blue;
        }
        &--red {
          background-color: $red;
        }
        &--dark-red {
          background-color: $dark-red;
        }
        &--green {
          background-color: $green;
        }
        &--beige {
          background-color: $beige;
          border: 1px solid $gray;
        }
      }
      &__grid {
        text-align: center;
        &:not(:last-of-type) {
          margin-bottom: $global-margin;
        }
        p {
          background-color: $light-gray;
          padding: $global-padding 0;
        }
      }
      &#helpers {
        .styleguide__section {
          div {
            margin-bottom: $global-margin * 2;
          }
        }
      }
      &#icons {
        svg {
          width: 35px;
          height: 35px;
          display: inline-block;
          margin-right: $global-margin;
        }
      }
      &#buttons {
        button {
          margin: $global-margin / 2;
        }
        .home__block1 {
          margin-top: $global-margin;
        }
      }
      .deliveryform {
        label,
        select {
          float: left;
        }
      }
      .search-form {
        @include media-breakpoint-down(sm) {
          margin-bottom: $global-margin;
          position: relative;
          left: 0;
          .btn {
            top: 0;
            right: 0;
          }
        }
      }
      .product-list,
      .card-block,
      .alert:last-of-type,
      .form-group {
        margin-bottom: 0;
      }
    }
  }
}
