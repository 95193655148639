.category-navbar {

  .navbar-toggler {
    background: #fbbc06;
    padding: 0;
    &__icon{
      fill: #194C2F;
    }

    .navbar-toggler-icon {
      display: none;
    }

    .close-icon {
      font-size: 27px;
    }
  }

  .collapsed {
    background: #fff;
    padding: 0;

    .navbar-toggler-icon {
      display: block;
    }

    .close-icon {
      display: none;
    }
  }

  .navbar-collapse {
    .navbar-nav {
      padding: 0;

      .show {
        .nav-link {
          &::after {
            transform: rotate(180deg);
          }
        }
      }

      .nav-link {
        border: 0;
        background: #f4f4f4;
        text-align: left;
        padding: 15px 10px;

        &::after {
          position: absolute;
          right: 30px;
          top: 30px;
        }
      }
    }
  }
  .dropdown-menu {
    border: 0;
    padding: 0;
    position: relative !important;
    transform: none !important;
    margin: 0;

    .dropdown-item {
      border-bottom: 1px solid #f4f4f4;
    }
  }

  #navbar_desktop {
    display: none;
  }

  button {
    outline: none;

    &:active {
      outline: none;
    }
  }

  .navbar-collapse {
    width: 100%;
    position: absolute;
    left: 0;
    margin-top: 1rem;
    z-index: 1;
  }

  ul {
    list-style-type: none;
    white-space: nowrap;
    overflow-x: scroll;
    padding: 0 15px;
    margin: auto;
    text-align: center;

    li {
      text-align: center;
      display: inline-block;

      a {
        @media (min-width: 991px) {
          padding: 15px;
        }
        color: #2d4838;
        font-weight: bold;
        display: block;
        &:hover {
          text-decoration: none;
          border-bottom: 6px solid #2d4838;
        }
      }
    }
  }

  @media (max-width: 991px) {

    ul {
      display: flex;
      flex-direction: column;
      height: 500px;

      a {
        padding: 15px;
      }
    }
  }

  @media (min-width: 991px) {

    #navbar_desktop {
      display: block;
    }

    #navbar_mobile {
      display: none;

      a {
        border: 0;
      }
    }
  }
}

.stickyCategoryNavbar {
  position: fixed;
  top: 0;
  width: 100%;
}
