.action-modal{
	.modal-dialog {
	  @media (max-width: 600px) {
	    position: fixed;
	    top: auto;
	    right: auto;
	    left: auto;
	    bottom: 0;
	    width: 100%;
	    margin: 0px;
	  }
	}
	.modal-title{
		@media (max-width: 600px) {
	    font-size: 16px;
	  }
	}
	.modal-content{
		width: 100%;
		max-width: 405px;
	  height: 250px;
	  @media (max-width: 600px) {
	    position: relative;
	    width: 100%;
    	max-width: 100%;
    	height: 304px;
  		animation: animatebottom 0.4s;
	  }
	}
	.modal-footer{
		@media (max-width: 600px) {
	    flex-direction: column-reverse;
	  }
	}
	.modal__actions{
		.btn{
			width: 150px;
		  @media (max-width: 600px) {
	    	margin: 6px 0px;
	    	width: 100%;
	  	}
		}

    .btn-large {
      width: 250px;
    }
	}
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}
