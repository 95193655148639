.justo-error__page{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 177px 0px;
  width: calc(100% - 86px);
  margin: auto;
  .error-page__exclamation{
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.72px;
    text-align: center;
    color: #006648;
    margin-bottom: 7px;
    margin-top: 63px;
  }
  .error-page__details{
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 0px;
  }
  svg{
    height: auto;
  }

  @media (max-width: 700px) {
    align-items: flex-start;
    padding-top: 64px;
    padding-bottom: 194px;
    .error-page__exclamation{
      font-size: 16px;
      letter-spacing: 0.48px;
    }
    .error-page__details{
      font-size: 16px;
      line-height: 1.31;
    }
  }
}

.justo-error__page.page-500{
  svg{
    max-width: 341px;
    @media (max-width: 700px) {
      max-width: 162px;
    }
  }
}

.justo-error__page.page-404{
  svg{
    max-width: 204px;
    @media (max-width: 700px) {
      max-width: 162px;
    }
  }
}