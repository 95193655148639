.landing-page {
  background-color: #f4f4f4;
  place-self: center;

  .row-wrapper {
    position: relative;
  }

  .login-register-label {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: 0.6px;
    color: #616161;
    position: absolute;
    right: 10px;
    top: 10px;
    a {
      color: $justo-green;
      font-weight: 500;
      margin: 8px;
    }
  }

  .container-image-landing {
    flex-direction: column;
    align-items: flex-end;
  }

  h1, h2 {
    color: $justo-green;
  }

  button {
    transform: none;
    box-shadow: none;
    flex: 1;
    height: 56px;
    border-radius: 4px;
    box-shadow: 2px 2px 2px 0 rgba(210, 210, 210, 0.5);
    background-color: #153176;
    font-size: 18px;
  }

  button span {
    color: #fff;
    transform: none;
    font-size: 12.5px;
    margin: 0;
    text-transform: none;
  }

  @media (max-width: $medium-screen) {
    width: 100%;
  }

  .row-wrapper {
    max-width: 1024px;
  }

  &__row-1 {
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #f4f4f4;
  }

  &__row-1 .row-wrapper {
    display: flex;
    flex-direction: row;
    padding-left: 62px;
    padding-top: 24px;

    @media (max-width: $medium-screen) {
      flex-direction: column;
      padding: 0;
    }

  }

  &__row-1-1 {
    max-width: 380px;

    @media (max-width: $medium-screen) {
      height: auto;
      margin: 0;
      padding: 35px;
      max-width: 100%;
      width: 100%;
    }

    .logo {
      width: 300px;
      object-fit: contain;
      margin-left: -25px;
    }

    .invalid-zip-code-label {
      margin-top: 27px;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: 0.6px;
      color: #616161;
      a {
        color: #616161;
        font-weight: 500;
        margin: 8px;
      }
    }

    .login-label {
      margin-top: 37px;
      color: $justo-green;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.19;
      letter-spacing: 0.9px;

      a {
        color: #616161;
        font-weight: normal;
        text-transform: uppercase;
        text-decoration: underline;
      }
    }

    h2 {
      margin-top: 12px;
      font-size: 40px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
    }

    span {
      display: flex;
      margin-top: 20px;
      font-size: 22px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: 1px;
      color: #000000;
    }
  }

  &__row-1-2 {
    flex: 1;
    width: 600px;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;

    img {
      width: 474px;
      height: 451px;
      margin-top: 43px;
      margin-bottom: 75px;
    }

    @media (max-width: $medium-screen) {
      display: none;
    }
  }

  &__row-2 {
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #e6e6e6;
  }

  &__row-2 .row-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 21px;
    height: 636px;

    @media (max-width: $medium-screen) {
      padding-top: 50px;
      padding-left: 15px;
      padding-right: 15px;
      height: auto;
      background-image: none !important;
      flex-direction: column;
    }

    .row-2-1-image {
      width: 600px;
      height: 600px;
      left: -140px;
      position: relative;

      @media (max-width: $medium-screen) {
        width: 100%;
        height: 200px;
        object-fit: contain;
        position: inherit;
      } 
    }
  }

  &__row-2-2 {
    .list {
      display: flex;
      flex-direction: row;
      place-items: flex-start;
      margin-top: 60px;
      @media (max-width: $medium-screen) {
        margin-top: 20px;
      }
    }

    .list:first-child {
      margin-top: 110px;
      @media (max-width: $medium-screen) {
        margin-top: 20px;
      }
    }

    .list img {
      margin-left: 35px;
      margin-right: 24px;
      width: 48px;
    }

    .list span {
      width: 321px;
      font-size: 28px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;

      @media (max-width: $medium-screen) {
        width: 100%;
      }
    }
  }

  &__row-3 {
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #f4f4f4;
  }

  &__row-3 .row-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 28px;

    @media (max-width: $medium-screen) {
      margin-top: 30px;
      height: auto;
      flex-direction: column;
    }

    h2 {
      text-align: center;
      font-size: 47.8px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
    }

    h3 {
      font-size: 19px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      margin-bottom: 30px;
    }

    .cards {
      display: flex;
      flex-direction: row;
      place-items: flex-start;
      margin: 41px 35px 0 35px;

      @media (max-width: $medium-screen) {
        margin-top: 0;
        height: auto;
        flex-direction: column;
      }

      .card-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        place-items: center;
      }

      .card {
        background: $justo-green;
        color: #fff;
        margin: 0 17px 0 17px;
        height: 321px;
        padding: 40px;
        font-size: 12px;
        border-radius: 30px;

        @media (max-width: $medium-screen) {
          width:100%;
          margin: 30px 0 0 0;
        }
      }

      img {
        margin-top: 28px;
        margin-bottom: 48px;
      }

      img.img1 {
        width: 100px;
        height: 86px;
        // @media (max-width: $medium-screen) {
        //   display: none;
        // }
      }

      img.img2 {
        width: 68px;
        height: 84px;
        // @media (max-width: $medium-screen) {
        //   display: none;
        // }
      }

      img.img3 {
        width: 193px;
        height: 62px;
        // @media (max-width: $medium-screen) {
        //   display: none;
        // }
      }
    }
  }

  &__row-4 {
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #e6e6e6;
  }

  &__row-4 .row-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 63px;
    padding-top: 45px;
    padding-bottom: 49px;
    background-size: 338px 170px;
    background-repeat: no-repeat;
    background-position: center 50px;
    width: 1024px;

    img.img1 {
      width: 465px;
      height: 177px;
      position: absolute;
      top: 200px;
      left: 50px;

      @media (max-width: $medium-screen) {
        width: 300px;
      }
    }

    h1{
      font-size: 99px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      width: 530px;

      @media (max-width: $medium-screen) {
        left: 0;
        width: 100%;
        padding: 15px;
        font-size: 50px;
        text-align: center;
      }

    }

    .row-register-wrapper {
      // display: flex;
      // flex-direction: row;
      // place-items: baseline;

      button {
        margin-left: 25px;
        margin-right: 75px;
        width: 180px;
      }

      .register-msg {
        margin-top: 45px;
        width: 550px;
        font-size: 22px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: 1px;
        color: #000000;
        display: inline-block;

        @media (max-width: $medium-screen) {
          width: 100%;
        }
      }
    }

    img.img2 {
      width: 223px;
      height: 117px;
      object-fit: contain;
      margin-top: 36px;

      @media (max-width: $medium-screen) {
        width: 300px;
      }
    }
  }

  &__row-5 {
    display: flex;
    justify-content: center;
    width: 100vw;
    background: $justo-green;
  }

  &__row-5 .row-wrapper {
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .row1 {
      img {
        width: 200px;
        height: 100px;
        object-fit: contain;
      }
    }

    .row2 {
      margin-top: 23px;
      img {
        width: 47px;
        height: 47px;
        object-fit: contain;
        border-radius: 14px;
        margin-right: 5px;
        margin-left: 5px;
      }
    }

    .row3 {
      margin-top: 18px;
      margin-bottom: 35px;
      span {
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: 0.2px;
        text-align: left;
        color: #ffffff;
      }
    }

  }

  @media (max-width: $medium-screen) {
    place-self: center;
  }
}
