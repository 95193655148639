@keyframes pulsate {
  0% {
    transform: scale(0.75);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(0.75);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.autorization-message{
  width: 403px;
  height: 55px;
  margin: 24px 0 25px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #828282;
  @media (max-width: 900px){
    width: 100%;
    color: #828282;
  }
}

.order-confirm{
  width: 96%;
  margin: 20px auto;
  margin-top: 0px;
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  &__details{
    width: calc(100% - 443px);
    padding-right: 40px;
    margin-bottom: 20px;
    hr{
      margin: 10px 0px;
    }
    .confirm-details__title{
      font-size: 20.8px;
      text-transform: uppercase;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
      margin-bottom: 40px;
    }
    .confirm-details__subtitle{
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
      margin-top: 22px;
      text-transform: uppercase;
    }
    .confirm-details__detail{
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
      margin-bottom: 22px;
    }
    .confirm-details__date{
      margin: 0px;
    }
    .confirm-details__products{
      width: 100%;
      tr th{
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #595959;
        text-align: left;
        white-space: nowrap;
        &:first-child{
          text-align: left;
          white-space: normal;
        }
        &:last-child{
          text-align: right;
          white-space: normal;
        }
      }
      tr td{
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #595959;
        text-align: left;
        white-space: nowrap;
        hr{
          margin: 10px 0px;
          border-top: 2px solid #595959;
        }
        &:first-child{
          text-align: left;
          white-space: normal;
        }
        &:last-child{
          text-align: right;
          white-space: normal;
        }
      }
    }
    @media (max-width: 991px){
      padding-right: 0px;
      width: 100%;
    }
    @media (max-width: 600px){
      .confirm-details__title{
        margin-bottom: 10px;
      }
      .confirm-details__subtitle{
        font-size: 16px;
      }
      .confirm-details__detail{
        font-size: 16px;
      }
      .confirm-details__products{
        tr th{
          font-size: 14px;
        }
      }
    }
  }
  &__summary{
    width: 100%;
    max-width: 443px;
    display: flex;
    flex-direction: column;
    hr{
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    .confirm-summary__title{
      color: #333333;
      font-size: 16px;
      font-weight: bold;
      margin: 0px;
      margin-bottom: 24px;
    }
    .confirm-summary__subtitle{
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.37px;
      margin-bottom: 0px;
      margin: 8px 0px;
      text-transform: uppercase;
    }
    .confirm-summary__section {
      justify-content: space-between;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 16px;
      h3{
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        margin: 0px;
        margin-bottom: 24px;
      }
      p{
        color: #828282;
        font-size: 16px;
        margin: 0px;
      }
      p.summary-section__value{
        color: #333333;
      }
      .confirm-summary__subdetail{
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .confirm-summary__comments{
        display: block;
        border-radius: 8px;
        border: solid 1px #dad8d7;
        background-color: $white;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #333333;
        padding: 10px;
        height: 142px;
        width: 100%;
      }
    }
    .confirm-summary__pay{
      width: 100%;
      margin-top: 10px;
      border-radius: 8px;
      padding: 16px;
      gap: 16px;
      border: 1px solid #E0E0E0;
      background: var(--neutral-00, #FFF);
      #paypal-button-container{
        display: flex;
        justify-content: center;
      }
      #mp-pay-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        background-color: #009EE3;
        border-color:#009EE3;
        border-radius: 4px;
        font-family: 'Sailec';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        height: 40px;
        color: white;
        width: 100%;
      }
      #pay-button{
        width: 100%;
      }
    }
    .confirm-summary__wallet[data-show=false]{
      display: none;
    }
    .confirm-summary__voucher{
      padding: 0px;
      #add-voucher-button{
        height: 37px;
        padding: 0px 10px;
      }
      .btn-voucher-remove {
        height: auto;
        float: right;
        position: relative;
        font-weight: 200;
        margin-left: 4px;
        color: #d3d1d0;
      }
      #id_discount-voucher:focus{
        box-shadow: none;
      }
    }
    .confirm-summary__discount{
      display: none;
      &[data-show=true]{
        display: block;
      }
      .summary-discount__title{
        color: #828282;
        font-size: 16px;
        font-weight: bold;
      }
      .summary-discount__value{
        color: #f2994a;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .confirm-summary__section,
      .confirm-summary__section[data-show=false]{
        display: none;
      }
      .confirm-summary__section[data-show=true]{
        display: flex;
      }
    }
    .checkout__review__section{
      border: 0px;
      width: 100%;
      #checkout-notes-form{
        padding: 0px;
      }
    }
    .shipping-free__warning{
      color: #828282;
      text-align: center;
      font-size: 12px;
      padding: 0px 6px;
      display: block;
      border-radius: 4px;
      border: solid 2px #f2994a;
    }
    @media (max-width: 991px){
      max-width: 100%;
      margin: 10px 0px;
      .confirm-summary__pay{
        position: fixed;
        left: 0px;
        bottom: 0px;
        padding: 20px;
        box-shadow: 4px -5px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
      }
    }
    @media (max-width: 600px){
      .confirm-summary__title,
      .confirm-summary__subtitle{
        font-size: 16px;
      }
      .confirm-summary__section{
        p,
        .confirm-summary__comments{
          font-size: 16px;
        }
      }
    }
  }
  .summary-discount__free{
    display: flex;
    width: 60px;
    height: 21px;
    border-radius: 4px;
    background-color: #f2994a;
    font-size: 12px;
    color: #f2f2f2;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    text-transform: uppercase;
    &.not-margin{
      margin-right: 0px;
    }
  }
  .order-loading{
    position: fixed;
    left: 0px;
    top: 0px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000000000000;
    opacity: 1;
    display: flex;
    width: 100%;
    height: 100%;
    .order-loading__container{
      max-width: 450px;
      width: 90%;
      margin: auto;
      height: 340px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border-radius: 0.3rem;
      border: 1px solid #888;
      flex-direction: column;
      p{
        margin: 0px;
        margin-top: 30px;
        font-size: 22px;
        font-weight: 400;
      }
      .payment-loader-container {
        margin: 0px;
        .payment-loader {
          width: 125px;
          height: 125px;
          margin: 0 auto;
          .payment-circle {
            text-align: center;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 5px solid lightgray;
            .payment-inner-circle {
              position: relative;
              left: -12.5%;
              top: 35%;
              width: 125%;
              height: 25%;
              background-color: white;
              -webkit-animation: rotate 2s infinite linear;
              animation: rotate 2s infinite linear;
            }
            h1 {
              position: relative;
              color: darkgray;
              top: -0.25em;
              margin-bottom: 0px;
              line-height: inherit;
              -webkit-animation: pulsate 1.25s infinite ease;
              animation: pulsate 1.25s infinite ease;
            }
          }
        }
      }
    }
  }
  @media (max-width: 991px){
    padding-bottom: 70px;
    flex-wrap: wrap;
  }
}
