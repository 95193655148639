.checkout__makeover{
    max-width: 382px;
    margin-right: 20px;
    margin-left: auto;
    &-title{
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.2px;
        color: #000000;
        border-bottom: 0.5px solid #979797;
        padding-bottom: 13px;
        margin-bottom: 10.5px;
    }
    &-repayment{
        width: 100%;
        height: auto;
        white-space: normal;
        padding: 10px 0px;
    }
    &-subtitle{
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.9px;
        color: #000000;
    }
    &-types{
        display: flex;
        border-bottom: 1px solid #979797;
        padding-bottom: 14px;
        margin-bottom: 14px;
        justify-content: space-around;
        .checkout__makeover-type{
            flex: 1;
            margin-right: 20px;
            cursor: pointer;
            &:last-child{
                margin-right: 0px;
            }
            .makeover__type-icon{
                height: 64px;
                width: 100%;
                border-radius: 5px;
                border: solid 0.5px #979797;
                background-color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            p{
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.45;
                letter-spacing: normal;
                text-align: center;
                margin-top: 8px;
                color: rgba(0, 0, 0, 0.85);
                margin-bottom: 0px;
            }
            &.active{
                .makeover__type-icon{
                    border: solid 0.5px #006648;
                    background-color: #d9e8e4;
                }
                p{
                    color: #006648;
                }
            }
            @media (max-width: 600px) {
                margin-right: 12px;
            }
        }
    }
    &-panels{
        position: relative;
        [data-makeover-panel]{
            width: 100%;
        }
        [data-makeover-panel].active{
            animation: makeoverFadeAnimation 1s;
        }
        .checkout__makeover-cards{
            .makeover__cards-save{
                height: 40px;
                width: 100%;
                margin-bottom: 12px;
                input[type="radio"]{
                    display: none;
                }
                input[type="radio"]:checked + label.makeover__cards-radio {
                    border: 1px solid #006648;
                    i{
                        border: 1px solid #006648;
                        background-color: #006648;
                    }
                    p{
                        color: 1px solid #006648;
                    }
                }
                label.makeover__cards-radio{
                    height: 100%;
                    width: 100%;
                    border-radius: 8px;
                    border: solid 0.5px #616161;
                    padding: 12px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                    i{
                        width: 12.8px;
                        height: 12.8px;
                        border: 1px solid rgba(0, 0, 0, 0.85);
                        border-radius: 50%;
                        display: block;
                        margin-right: 12px;
                    }
                    p{
                        font-size: 13px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.38;
                        letter-spacing: normal;
                        color: rgba(0, 0, 0, 0.8);
                        margin-bottom: 0px;
                    }
                }
            }
            .makeover__cards-save:last-child{
                margin-bottom: 0px;
            }
            .makeover__form-new{
                height: auto;
                overflow: hidden;
                label.makeover__cards-radio{
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 0px;
                    .makeover__form-new__title{
                        display: flex;
                        align-items: center;
                        div{
                            width: 12.8px;
                            height: 12.8px;
                            display: block;
                            margin-right: 12px;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                        }
                    }
                    .makeover__form{
                        display: none;
                    }
                }
            }
        }
        .checkout__makeover-vales{
            .checkout__makeover-vales_gateways{
                display: flex;
                .checkout__makeover-vale{
                    max-width: 120px;
                    flex: 1;
                    margin-right: 10px;
                    &:last-child{
                        margin-right: 0px;
                    }
                    input[type=radio]{
                        display: none;
                        & + label{
                            cursor: pointer;
                            height: 40px;
                            width: 100%;
                            border-radius: 5px;
                            border: solid 0.5px #979797;
                            background-color: #ffffff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                height: 18px;
                                width: auto;
                            }
                        }
                        &:disabled + label{
                            cursor: no-drop;
                        }
                        &:checked + label{
                            border: solid 0.5px #006648;
                            background-color: #d9e8e4;
                        }
                    }
                }
            }
            .makeover__vales-form{
                width: 100%;
                border-radius: 8px;
                border: 1px solid #006648;
                padding: 12px;
                p{
                    font-size: 13px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    color: #006648;
                    margin-bottom: 12px;
                }
            }
        }
        .checkout__makeover-others{
            .paypal-gateway,
            .pod-gateway{
                margin-bottom: 16px;
                &__slogan{
                    font-size: 17px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.9px;
                    color: #000000;
                    margin-bottom: 4px;
                }
                &__description{
                    font-size: 13.9px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.15;
                    letter-spacing: normal;
                    color: rgba(0, 0, 0, 0.85);
                    margin-bottom: 0px;
                }
            }
        }
    }
    &-submit{
        margin-top: 16px;
        button{
            width: 132px;
            height: 36px;
            border-radius: 8px;
            margin-left: auto;
            &:disabled {
                background-color: #e0e0e0 !important;
                border-color: #e0e0e0 !important;
                color: #9e9e9e;
            }
        }
    }
    .makeover__edenred-warning{
      font-size: 12px;
      color: #000000;
      margin-bottom: 20px;
    }
    .makeover__form{
        .makeover__form-group{
            height: 32px;
            border-radius: 4px;
            border: solid 0.5px #154734;
            margin: 12px 0px;
            display: flex;
            align-items: center;
            &:last-child{
                margin-bottom: 0px;
            }
            &[data-disabled]{
                border-color: #979797;
            }
            img{
                padding-left: 8px;
            }
            input[type=text],
            input[type=text]:active,
            input[type=text]:focus,
            input[type=text]:hover{
                padding: 8px;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.64px;
                color: #154734;
                border: none;
                background-color: transparent;
                @media (max-width: 400px) {
                    font-size: 10px;
                }
            }
            input[type=text].error{
                color: #eb001b !important;
            }
            input[type=text][name=name]{
                width: 100%;
                height: 100%;
                width: 100%;
            }
            input[type=text][name=number]{
                width: 60%;
            }
            input[type=text][name=date],
            input[type=text][name=cvv]{
                width: 20%;
            }
            input[type=text]::placeholder{
                color: rgba(0, 0, 0, 0.7);
            }
        }
        .makeover__form-extras{
            margin-top: 12px;
            .checkbox{
                .checkmark{
                    width: 14px;
                    height: 14px;
                    border-radius: 4px;
                }
                .label{
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9b9b9b;
                }
            }
        }
    }
    .makeover-is__alcohol{
        width: 100%;
        min-height: 129px;
        border-radius: 8px;
        border: solid 0.5px #f4364c;
        background-color: rgba(244, 54, 76, 0.1);
        padding: 18px 12px;
        margin-bottom: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .is-alcohol__message{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 18px;
            svg{
                width: 40px;
                height: auto;
                margin-right: 12px;
            }
            p{
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: normal;
                color: #f4364c;
                flex: 1;
                margin-bottom: 0px;
            }
        }
        .is-alcohol__return{
            text-align: right;
            a,
            a:hover{
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.31px;
                text-align: right;
                color: #f4364c;
                border-bottom: 1px solid #f4364c;
                padding-bottom: 2px;
                text-decoration: none;
            }
        }
    }
    @media (max-width: 991px) {
        margin: auto;
    }
}
@keyframes makeoverFadeAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
}