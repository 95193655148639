@import "fonts/fonts";
@import "variables";

@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/scss/main";
@import "~@adyen/adyen-web/dist/adyen.css";

@import "components/breadcrumbs";
@import "components/buttons";
@import "components/cards";
@import "components/checkout-dropdown";
@import "components/filters";
@import "components/forms";
@import "components/header";
@import "components/labels";
@import "components/loader";
@import "components/pagination";
@import "components/table";
@import "components/tabs";
@import "components/typography";
@import "components/variant-picker";
@import "components/category-nav";
@import "components/modals";
@import "components/product-card";
@import "components/checkout-voucher";
@import "components/balloon";
@import "components/whatsapp_ballon";
@import "components/not-in-stock";
@import "components/adyen";
@import "components/banner-category";
@import "components/spinner-button";

@import "layouts/checkout";
@import "layouts/landing-form";
@import "layouts/landing-page";
@import "layouts/no-results";
@import "layouts/order-details";
@import "layouts/payment";
@import "layouts/product-list";
@import "layouts/styleguide";
@import "layouts/privacity_advice";
@import "layouts/about";
@import "layouts/faq";
@import "layouts/order-summary";
@import "layouts/error-pages";
@import "layouts/wallet-events";
@import "layouts/search";
@import "layouts/referrals";
@import "layouts/checkout-success";
@import "layouts/makeover";
@import "layouts/no-coverage";

.alert {
  z-index: 5;
  top: 0;
}

.maincontent {
  max-width: 1100px;
  width: calc(100% - 40px);
  margin: auto;
}

#header {
  position: sticky;
  z-index: 2;
  top: 0px;
  height: 78px;
}
