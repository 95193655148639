.no-coverage-container {
    max-width: 380px;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0 auto;

    .no-coverage-title-wrapper {
        padding: 20px 0px 20px 0px;
        .no-coverage-title {
            color: #006648;
            font-weight: bold;
            font-size: 24px;
            text-align: center;
        }
    }

    .no-coverage-sub-wrapper {
        text-align: left;
        .no-coverage-subtitle {
            font-size: 22px;
        }
    }

    #email-form {
        width: 100%;

        input {
            height: 36px;
            width: 100%;
            margin-top: 0px;
            // border-radius: 6px 0px 0px 6px;
            border: solid 1px black;
            background-color: #fafafa;
            padding: 8px;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
        }

        label {
            margin: 15px 0px 0px 0px;
            font-weight: bold;
        }
        .email-form-button {
            background-color: #006648;
            color: white;
            border-radius: 5%;
            border: none;
            width: 15%;
            padding: 5px 10px 5px 10px;
            margin-top: 10px;

            img {
                transform: rotate(180deg);
            }
        }

        .wrapper-email-input {
            display: flex;
        }

        blockquote {
            color: red;
        }
    }

}
