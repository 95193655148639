// GLOBAL
$global-padding: 1rem;
$global-margin: 1rem;
$button-border-radius: 12px;

// COLORS
$body-color: #333333;
$white: #fff;
$light-gray: #f1f5f5;
$gray: #d3d1d0;
$darken-gray: darken($gray, 10%);
$skull-gray: #828282;
$blue: #26a5d4;
$darken-blue: darken($blue, 10%);
$dark-blue: #21125e;
$red: #eb5757;
$dark-red: #c53636;
$green: #27ae60;
$light-green: #006648;
$beige: #fffaec;
$turquoise: #013171;
$light-turquoise: #013171;

//JUSTO COLORS
$justo-green: #024638;
$justo-yellow: #FFC102;
$justo-blue: #013171;
$justo-red: #D7384A;
$justo-grey: #E2E2E2;

// TYPOGRAPHY
$font-family-sans-serif: "Lato", sans-serif;
$base-font-size: 1rem; // 16px
$small-font-size: 0.875rem; // 14px
$base-line-height: 1.25rem; // 20px
$h1-font-size: 3rem;
$h2-font-size: 1.3rem;
$h3-font-size: 1rem;
$h4-font-size: 1.125rem; // 18px
$bold-font-weight: 600;
$extra-bold-font-weight: 800;

// spacing
$spacer: 1rem; // 16px

// breakpoints
$medium-screen: 992px;
$small-screen: 540px;

$theme-colors: (
  primary: $light-turquoise,
  secondary: $white,
  success: $green,
  info: $turquoise,
  warning: #ffc107,
  danger: $red,
  light: #f8f9fa,
  dark: #343a40
);

// breakpoints
$medium-screen: 992px;
$small-screen: 540px;

:export {
  mediumScreen: $medium-screen;
  smallScreen: $small-screen;
}
