.form-control,
input {
  color: $body-color;
  border-radius: 8px;
  border: 1px solid $gray;
  &:focus {
    color: $body-color;
    border-color: $gray;
    background-color: $beige;
    outline: none;
  }
  &::placeholder {
    color: $gray;
    font-weight: 300;
  }
  &[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: solid 2px #d3d1d1;
    background-color: #fff;
    &:hover {
      background-color: #d3d1d1;
    }
    &:checked {
      background-color: #024638;
      border: solid 2px #024638;
      &:after {
        opacity: 1;
      }
    }
    &:after {
      display: block;
      left: 5px;
      top: 0px;
      width: 7px;
      height: 12px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      position: absolute;
      opacity: 0;
      content: "";
      transition: opacity 300ms;
    }
    & ~ .form-check-label {
      margin-left: 8px;
      bottom: 4px;
      cursor: pointer;
    }
  }
}

.form-check {
  display: flex;
  flex-direction: row;
}

.styled-select {
  width: 220px;
  background: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, #2d4838, #2d4838);
  background-position: calc(100% - 21px) calc(1em + 2px),
    calc(100% - 16px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: thin solid green;
  border-color: #2d4838;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0em 1em;
  border-radius: 4px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-top: 0px;
  height: 42px;
  cursor: pointer;
}

.styled-select:focus {
  background-color: white;
}

.form-group {
  text-align: left;
  position: relative;
  .phone-prefix-input {
    display: grid;
    grid-template-columns: 52px calc(100% - 52px);
    align-items: stretch;
    input {
      border-left-color: transparent;
      border: {
        bottom-left-radius: 0;
        top-left-radius: 0;
      }
    }
    select {
      padding: 0px 6px;
      border: {
        left: 1px solid #d3d1d0;
        bottom-left-radius: 8px;
        top-left-radius: 8px;
        bottom-right-radius: 0;
        top-right-radius: 0;
      }
    }
  }
}

.input-btn {
  position: relative;
  .btn {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.deliveryform {
  select {
    @include media-breakpoint-down(md) {
      width: 100%;
      margin-top: $global-margin / 2;
      margin-left: 0;
    }
    background-color: $white;
    height: calc(2.25rem + 2px);
    &:focus {
      outline: none;
    }
  }
}

.search-form {
  width: 100%;
  position: relative;
  transition: $transition-base;
  &.search-form--hidden {
    transform: translateX(-100vw);
  }
  &__close-icon {
    position: absolute;
    cursor: pointer;
    padding: $global-padding * 1.9 $global-padding;
    img {
      width: 22px;
    }
  }
  .form-control {
    height: 53px;
    width: 100%;
    border-radius: $button-border-radius;
    padding: $global-padding;
    font-size: 0.8rem;
  }
  .btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    border-left: solid 1px $gray;
    outline: 0;
    display: flex;
    align-items: center;
    @include transition(background, 0.3s);
    img {
      height: 1.5rem;
      padding: $global-padding / 7;
    }
  }
  @include media-breakpoint-down(sm) {
    display: block;
    left: 0;
    position: absolute;
    max-width: 100%;
    float: right;
    top: 0;
    z-index: 5;
    margin-top: 0;
    .form-control {
      padding: $global-padding * 2 $global-padding * 2 $global-padding * 2
        $global-padding * 4;
      border: 0;
      height: 5.9rem;
    }
    .mobile-close-search {
      position: absolute;
      top: 1.7rem;
      left: 0.5rem;
    }
    .btn {
      border-radius: 0;
      height: 6rem;
      right: 0;
      top: 0;
    }
  }
}

input#id_quantity {
  &:focus {
    outline: none;
  }
}

.help-block {
  font-size: 0.8rem;
  margin-top: $global-margin / 2;
}

.has-error {
  .help-block {
    color: $red;
  }
}

.radio {
  margin-bottom: $global-margin / 2;
  ul {
    list-style: none;
    padding-left: 0;
  }
  input {
    -webkit-appearance: none;
    cursor: pointer;
    border: 1px solid #2d4838;
    display: inline-block;
    height: 14px;
    width: 14px;
    min-height: 14px;
    min-width: 14px;
    margin: $global-margin / 4 $global-margin / 2 0 0;
    position: relative;
    vertical-align: top;
    &:checked {
      &:after {
        background: #2d4838;
        border-radius: 100%;
        content: "";
        height: 8px;
        left: 2px;
        position: absolute;
        top: 2px;
        width: 8px;
      }
    }
  }
  span,
  .address-details {
    display: inline-block;
  }
  label {
    margin-right: $global-margin;
    span {
      display: inline;
    }
  }
}

.relative {
  position: relative;
}

.passIcon {
  max-width: 15px;
  max-height: 15px;
  position: absolute;
  top: 0.8rem;
  right: 0.5rem;
  cursor: pointer;
}

// ATM there is no better solution to set
// border-radius on select inputs - 12.2017
select.form-control {
  appearance: none;
  border-radius: 2px;
  background: url("../images/arrow-select.svg") 100% 50% no-repeat / 24px 14px;
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

.align-left {
  width: 100%;
  text-align: left;
}

.password-with-icon {
  margin-bottom: 8px;
}

.password-with-icon .input-group-append {
  position: absolute;
  right: 0;
  z-index: 3;
}

.password-with-icon .input-group-text {
  all: initial;
  width: 32px;
}

.password-with-icon input {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

#password-error-message {
  margin-bottom: 16px;
}
