.checkout-preview-dropdown {
  width: 28rem;
  .btn-primary {
    border-radius: 6px;
    padding: 0.5rem 2rem;
  }
  .container {
    padding: 0;
    background-color: $white;
  }
  &__list {
    max-height: 27rem;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: solid 1px $gray;
    border-bottom: solid 1px $gray;
    &:first-child {
      border-top: none;
    }
    &.overflow {
      overflow-y: hidden;
    }
    background: $white;
    .item {
      height: 6rem;
      border-bottom: solid 1px $gray;
      align-items: center;
      padding: 0 1rem;
      width: 100%;
      h3 {
        margin-bottom: 0;
        span {
          color: $darken-gray;
        }
        p {
          margin-bottom: 0;
          padding-top: $global-padding * 0.2;
          color: $darken-gray;
        }
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  &__total {
    h3 {
      padding-top: $global-padding * 1.4;
      padding-left: $global-padding * 5;
      span {
        color: $darken-gray;
        font-size: 0.9rem;
      }
      &.price {
        padding-right: $global-padding * 2.3;
        &.single {
          padding-right: $global-padding * 1.4;
        }
      }
    }
  }
  &__button {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 2rem;

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__image {
    float: left;
    width: 60px;
    height: 60px;
    margin: $global-margin * 0.6;
  }
  &__actions {
    margin-top: $global-margin * 2;
  }
  &__empty {
    img {
      margin: $global-margin 0;
    }
  }
  @include media-breakpoint-up(md) {
    &.show {
    display: block !important;
  }
  }
}
.dropdown-item{
  &.active, 
  &:active{
    background-color: transparent;
    color: #212529;
  }
}