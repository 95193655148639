.wallet-events{
  margin: auto;
  max-width: 900px;
  width: 90%;
  min-height: calc(100vh - 250px);
  &__title{
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #154734;
  }
  table{
    width: 100%;
    thead{
      border-bottom: 1px solid;
      tr th{
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        color: #154734;
        padding: 0px 6px;
        padding-top: 4.5px;
        text-align: center;
        text-transform: uppercase;
        &:first-child{
          text-align: left;
        }
        &:last-child{
          text-align: right;
        }
      }
    }
    tbody{
      tr {
        border-bottom: 1px solid;
        td{
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.4px;
          color: rgba(0, 0, 0, 0.85);
          padding: 30px 6px;
          text-align: center;
          &:first-child{
            text-align: left;
          }
          &:last-child{
            text-align: right;
          }
        }
      }
      tr:last-child{
        border-bottom: none;
      }
    }
  }
  @media (max-width: 768px) {
    &__title{
      text-align: center;
    }
    table{
      thead{
        tr th{
          font-size: 12px;
        }
      }
      tbody{
        tr td{
          font-size: 12px;
          padding: 10px 0px;
        }
      }
      .wallet-event__head_order,
      .wallet-event__order{
        display: none;
      }
    }
  }
}