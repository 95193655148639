.my-checkout {
  padding: 40px 70px;
  &__title {
    font-size: 28px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 48px;
  }
  &__line {
    padding-bottom: 34px;
    margin-bottom: 16px;
    display: flex;
    border-bottom: 0.5px solid #f2f2f2;
    .checkout-line__info {
      display: flex;
      align-items: flex-start;
      flex: 1;
      padding-right: 50px;
      &:hover {
        text-decoration: none;
      }
      .checkout-line__image {
        width: 50px;
        height: 50px;
        object-fit: contain;
        margin-right: 15px;
      }
      .checkout-line__name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 0px;
      }
    }
    .checkout-line__quantity {
      max-width: 310px;
      width: 100%;
      .checkoutLinePicker {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      padding-bottom: 16px;
      .checkout-line__info {
        margin-bottom: 18px;
        padding-right: 0px;
      }
      .checkout-line__quantity {
        max-width: 100%;
        .checkoutLinePicker {
          max-width: 560px;
          margin: auto;
        }
      }
    }
  }
  &__total {
    display: flex;
    flex-direction: column;
    .checkout-total__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      .checkout-total__item-title {
        font-size: 24px;
        color: #000000;
        margin-bottom: 0px;
      }
      .checkout-total__item-price {
        font-size: 24px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 0px;
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a,
    a:hover,
    button {
      padding: 0px;
      margin: 0px;
      border: 0px;
      background: transparent;
      font-size: 16px;
      color: #33856d;
      text-decoration: none;
      margin-right: 66px;
    }
    .my-checkout__pay,
    .my-checkout__pay:hover {
      border-radius: 6px;
      background-color: #fbdc74;
      width: 210px;
      height: 35px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0px;
    }
    @media (max-width: 768px) {
      .action-pay__container {
        position: fixed;
        left: 0px;
        bottom: 0px;
        padding: 10px;
        background: #fff;
        width: 100%;
        box-shadow: 4px -5px 4px 0 rgba(0, 0, 0, 0.1);
        z-index: 10;
        display: flex;
        justify-content: center;
        a,
        a:hover {
          width: 100%;
          max-width: 450px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    padding: 60px 0px;
  }
}
.not-availible-msg {
  color: red;
}

.product-not-available {
  color: #838383;
}

.disabled-card-switch {
  border: 1px solid #838383;
}

.disabled-card-switch input {
  color: #838383 !important;
}

.quantity-container {
  margin-top: 1rem;
  .checkout-preview-item-delete {
    margin-left: 20px;
  }
  .checkout-preview-item-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 42px;
    .checkout-item__total {
      font-weight: bold;
    }
    .checkout-item__kg {
      font-size: 14px;
    }
  }
}
.checkout {
  margin-bottom: $global-margin * 5;
  h2,
  h3 {
    margin: 1rem 0;
  }
  hr {
    margin: $global-margin * 3 0 $global-margin * 2;
  }
  hr.margin-default {
    margin: initial;
  }
  .primary {
    margin-top: $global-margin;
  }
  &__title {
    font-size: 20.8px;
    text-transform: uppercase;
  }
  &__subTitle {
    font-size: 16px;
    text-transform: uppercase;
  }
  &__edit-link {
    margin-top: $global-margin;
  }
  &__login {
    margin: $global-margin * 5 0 $global-margin * 3;
    border: 1px solid $gray;
    border-radius: $button-border-radius;
    text-align: center;
    h3 {
      margin-bottom: $global-margin * 3;
    }
    .btn {
      margin: $global-margin * 2 auto 0;
    }
    &__register {
      background: url("../images/login-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__register_action {
      padding: $global-padding * 2 0 $global-padding;
    }
    &__form {
      background-color: $light-gray;
      padding: $global-padding * 2 $global-padding * 6 $global-padding * 2;
      @include media-breakpoint-down(md) {
        padding: $global-padding * 2;
      }
      .form-group {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  &__review {
    background-color: $light-gray;
    padding: $global-padding $global-padding * 2;
    border-radius: 3px;
    p {
      margin: $global-margin 0;
    }
    &__section_borderless {
      padding-top: $global-padding;
      .input-btn {
        input {
          border-radius: 12px;
        }
        .btn {
          height: calc(2.25rem + 2px);
          padding: $global-padding / 2;
        }
        .btn-voucher-remove {
          position: relative;
        }
      }
      input {
        padding: $global-padding;
      }
    }
    &__section {
      border-top: 1px solid $gray;
      padding-top: $global-padding;

      .checkout-notes {
        width: 100%;
        border: 2px solid #d3d1d0;
        border-radius: 7px;
        height: 8rem;
        outline: none;
        padding: 0.5rem;

        &::placeholder {
          font-weight: 100;
        }
      }

      .checkout-notes-success {
        border: 2px solid #27ae60;
      }

      .checkout-notes-error {
        border: 2px solid #c53636;
      }
      #checkout-notes-button {
        padding: 0px 10px;
        float: right;
        margin-top: 1rem;
      }
      .input-btn {
        input {
          border-radius: 12px;
        }
        .btn {
          height: calc(2.25rem + 2px);
          padding: $global-padding / 2;
        }
      }
      input {
        padding: $global-padding;
      }
    }
    &__tittle {
      margin-bottom: $global-margin * 2;
    }
    &__variant {
      color: $skull-gray;
    }
    @include media-breakpoint-down(sm) {
      margin-top: $global-margin * 3;
    }
  }
  &__thankyoupage {
    text-align: center;
    margin-top: $global-margin * 6;
    @include media-breakpoint-down(sm) {
      margin-top: $global-margin * 3;
    }
    h3 {
      margin-bottom: $global-margin * 3;
    }
    .btn {
      margin-top: $global-margin;
    }
    span {
      color: $darken-gray;
    }
    h1 {
      font-weight: 300;
      margin-top: -$global-margin * 4;
    }
    &__form {
      margin-top: $global-margin * 6;
      margin-bottom: $global-margin * 3;
      border: 1px solid $light-gray;
      border-radius: $button-border-radius;
      @include media-breakpoint-down(sm) {
        margin-top: $global-margin * 3;
      }
      .col-md-6 {
        padding: $global-padding * 3 0 $global-padding * 2;
        &:first-of-type {
          background-color: $light-gray;
          padding: $global-padding * 3 $global-padding * 4 $global-padding * 2;
          @include media-breakpoint-down(md) {
            padding: $global-padding * 3 $global-padding * 2 $global-padding * 2;
          }
        }
      }
      img {
        margin-bottom: $global-margin * 1.2;
        @include media-breakpoint-only(md) {
          margin-bottom: $global-margin * 2.4;
        }
      }
    }
  }
  &__new-address {
    grid-column-end: span 2;
    @include media-breakpoint-down(md) {
      grid-column-end: span 1;
    }
    &-container {
      grid-column-end: span 2;
      @include media-breakpoint-down(md) {
        grid-column-end: span 1;
      }
    }
    label {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.16px;
      @media (max-width: 600px) {
        font-size: 20px;
      }
      @media (max-width: 450px) {
        font-size: 14px;
      }
      @media (max-width: 400px) {
        font-size: 10px;
      }
    }
    .checkout__country_area {
      @media (max-width: 450px) {
        flex: 1;
        width: 100%;
        max-width: 100%;
      }
    }
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 991px) {
      position: fixed;
      left: 0px;
      bottom: 0px;
      padding: 15px;
      background: $white;
      width: 100%;
      box-shadow: 4px -5px 4px 0 rgba(0, 0, 0, 0.1);
      z-index: 10;
      button.btn {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
  }
  &-actions.actions-left {
    justify-content: flex-start;
  }
  &__payment {
    h3 {
      margin: $global-margin * 2 0 $global-margin;
    }
    &-form {
      label {
        cursor: pointer;
        display: block;
        margin: 0;
      }
      &__choice {
        margin-bottom: $global-margin;
      }

      .payment-choices {
        margin: 1rem 0;
        .checkout__payment-save {
          label {
            display: flex;
            align-items: baseline;
            p {
              font-size: 16px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              margin: 0px;
              span {
                font-weight: normal;
              }
            }
          }
        }
        .checkout__payment-form__choice {
          margin: 1rem 1rem 0 0;
          width: 100%;
          height: 100px;
          label {
            .card {
              .card-body {
                display: flex;
                align-items: center;
                height: 100px;
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .checkout__payment-gateway ~ .checkout__payment-gateway {
          .card-body {
            label {
              display: flex;
              align-items: center;
              input {
                margin-top: 0px;
              }
              .gateway_info--brand {
                img {
                  height: 30px;
                  @media (max-width: 700px) {
                    height: 20px;
                  }
                }
              }
              .gateway_info--name {
                margin-left: 10px;
                p {
                  margin-bottom: 0px;
                  line-height: normal;
                }
              }
            }
          }
        }
      }

      .payment-restriction {
        border: 1px solid #ea444e;
        border-radius: 6px;
        background: #fbe7ea;
        display: flex;
        align-items: center;
        padding: 20px 0;
        margin: 1rem 0;

        svg {
          width: 36px;
          border-radius: 50%;
          background: #ea444e;
          height: 25px;
          padding: 6px;
          margin: 0 20px;
        }

        p {
          color: #ea444e;
          margin: 0;

          span {
            text-decoration: underline;
          }
        }
      }

      @media screen and (max-width: 768px) {
        .payment-choices {
          flex-direction: column;
        }

        .payment-restriction {
          flex-direction: column;

          svg {
            width: 25px;
            margin-bottom: 1rem;
          }

          p {
            padding: 0 1rem;
          }
        }
      }

      @media screen and (max-width: 991px) {
        .btn-secondary {
          margin-bottom: 1rem;
        }
      }
    }
  }
  &__payments {
    &-card-expiry-slash {
      pointer-events: none;
      font-size: 1.5rem;
      font-weight: 100;
    }
  }
  &__addresses {
    display: grid;
    grid-template-columns: 1fr;
    &--full-width {
      grid-template-columns: 1fr;
    }
    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
    &-panel {
      label {
        cursor: pointer;
        display: block;
        margin-right: 0;
        .card-body {
          display: flex;
          .address-details {
            flex: 1;
            address {
              font-size: 16px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
  &__billing {
    &-form {
      p {
        margin: $global-margin * 2 0;
      }
    }
  }
  &__success {
    width: 250px;
    margin-bottom: 37px;
  }
  &__save-caption {
    font-size: 16px;
    font-weight: normal;
    color: #2b2b2b;
  }
  &__save-list,
  &__save-list:hover {
    background-color: $justo-green;
    margin: 0px !important;
    width: 350px;
    height: 54px;
    border-radius: 6px;
    font-size: 16px;
    padding: 0px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
      width: 96%;
      max-width: 216px;
      height: 40px;
      font-size: 12px;
    }
  }
}
.action-save__container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.checkout-preview {
  h3 {
    color: $skull-gray;
  }
  &__line {
    &.last {
      border-bottom-color: $skull-gray;
    }
    .col-5 {
      margin-top: $global-margin / 2;
      @include media-breakpoint-down(sm) {
        text-align: right;
      }
    }
    &__product {
      a {
        transition: 0.3s;
        &:hover {
          color: $skull-gray;
        }
        p {
          margin-top: $global-margin / 2;
          margin-bottom: 0;
          line-height: 1rem;
          small {
            color: $skull-gray;
          }
        }
        img {
          vertical-align: top;
          margin-right: $global-margin;
        }
      }
    }
    &__quantity {
      @include media-breakpoint-down(sm) {
        margin-bottom: $global-margin / 2;
      }
      form {
        display: inline-block;
        input {
          width: 62px;
          margin-right: 10px;
        }
      }
      &-error {
        display: block;
      }
      img {
        max-width: 18px;
      }
    }
  }
  &__total {
    padding-top: $global-padding;
    p {
      margin-bottom: 0;
    }
    &__subtotal {
      color: $skull-gray;
      margin-bottom: 24px;
      @include media-breakpoint-up(sm) {
        padding-left: calc(60px + #{$global-padding} * 2);
      }
      &--shipping {
        color: $body-color;
      }
    }
    @media (max-width: 600px) {
      margin-bottom: 37px;
      border-bottom: 1px solid #d3d1d0;
    }
  }
  &__delivery-info {
    p {
      margin-bottom: 0;
      color: $body-color;
      display: inline-block;
    }
    &__price {
      text-transform: uppercase;
    }
  }
  &__actions-container,
  &__empty {
    margin-bottom: 38px;
  }

  &__actions {
    .checkout-preview__action {
      margin-left: 10px;
    }
    @media (max-width: 991px) {
      .checkout-preview__action-pay {
        position: fixed;
        left: 0px;
        bottom: 0px;
        padding: 15px;
        background: $white;
        width: 100%;
        box-shadow: 4px -5px 4px 0 rgba(0, 0, 0, 0.1);
        z-index: 10;
        button.btn {
          width: 100%;
          margin: 0px;
        }
      }
    }
    @media (max-width: 600px) {
      display: flex;
      justify-content: space-between;
      .checkout-preview__action {
        margin-left: 0px;
      }
      button.btn {
        width: calc(50% - 20px);
        min-width: 140px;
      }
      button.btn[data-full="true"] {
        width: 100%;
      }
    }
  }
  &__empty {
    text-align: center;
    img {
      margin: $global-margin * 2 0;
      width: 100%;
      @media (min-width: 768px) {
        width: auto;
      }
    }
    .btn {
      margin: $global-margin * 2 0 $global-margin * 5;
    }
  }
  &-item-delete {
    cursor: pointer;
    display: inline-block;
    margin-left: $global-margin / 2;
    svg {
      vertical-align: text-top;
    }
  }
}
.address-delivery {
  margin-bottom: 20px;
  @media (max-width: 991px) {
    margin-top: 40px;
    margin-bottom: 0px;
  }
}
.checkout_text {
  text-transform: lowercase;
}
.checkout_cart_img {
  width: 50px;
  height: 50px;
}
.modal {
  display: none;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
.address_name {
  font-weight: bold;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

#slot_expired_modal.modal {
  padding-top: 100px;

  @media (min-width: 992px) {
    padding-top: 200px;
  }
}

#slot_expired_modal .modal-body {
  position: relative; /* This avoids whatever it's absolute inside of it to go off the container */
  height: auto;
  padding: 15px;
}

#slot_expired_modal .img-wrapper {
  margin: 0 auto;
  text-align: center;
}

#slot_expired_modal .title {
  text-align: center;

  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0.5em;
    text-transform: none;
  }
}

#slot_expired_modal .modal-content {
  border-radius: 0.7em;
  height: auto;
  max-width: 544px;
  padding: 20px 60px;

  p {
    color: #4f4f4f;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
  }
}

#slot_expired_modal .modal-footer {
  border: none;
  padding: 0 1rem;
}

#slot_expired_modal .modal__actions a.btn {
  line-height: 1.5;
  margin: 0 auto;
}
