.breadcrumbs {
  margin: 1.5rem 0;
  text-transform: none;
  display: flex;
  align-items: center;
  li,
  span {
    display: inline;
    &:last-of-type {
      a {
        color: #111;
        font-weight: 600;
      }
    }
    &:not(:first-of-type) {
      margin-left: $global-margin / 3;
    }
    li a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    a {
      color: $skull-gray;
      font-weight: 500;
      font-size: 1rem;
      @media (max-width: 600px) {
        font-size: 12px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
.breadcrumbs-ancestor {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 3rem;
}

p.breadcrumbs {
  display: block !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  span:last-child {
    font-weight: bold;
  }
}
