#checkout-voucher {
  &.active {
    input[type="text"] {
      border: solid 1px #1c4e32;
      background-color: #fafafa;
      color: #024638;
      cursor: none;
      pointer-events: none;
    }
    button[type="submit"] {
      border: solid 0.5px #194c2f;
      background-color: #1c4e32;
      cursor: none;
      pointer-events: none;
      p {
        display: none;
      }
      svg {
        display: block;
      }
    }
  }
  form {
    display: flex;
    width: 100%;
    position: relative;
    input[type="text"] {
      height: 36px;
      flex: 1;
      border-radius: 6px 0px 0px 6px;
      border: solid 1px #aeb9b9;
      background-color: #fafafa;
      border-right: 0px;
      padding: 8px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    button[type="submit"] {
      color: #194c2f;
      width: 66px;
      height: 36px;
      border-radius: 0px 6px 6px 0px;
      border: solid 0.5px #1c4e32;
      background-color: #fafafa;
      padding: 0px;
      margin: 0px;
      p {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        margin: 0px;
      }
      svg {
        display: none;
        margin: auto;
      }
      &:focus {
        outline: none;
      }
    }
  }
  @media (max-width: 400px) {
    form {
      input[type="text"] {
        font-size: 12px;
      }
      button[type="submit"] {
        width: 50px;
        font-size: 12px;
      }
    }
  }
}
