.login-form {
  display: flex;
  flex-direction: column;
  margin-top: 37px;
  max-width: 360px;
  justify-content: space-between;
  place-content: center;

  .component-error-text{
    margin-top: 16px;
    color: $justo-red;

    ul {
      list-style: none;
      padding: 0px;
    }
  }

  .input-component {
    border-radius: 4px;
    border: solid 1px #a5a5a5;
    background-color: #f4f4f4;
    font-size: 12px;
    height: 36px;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    place-items: center;
    width: 360px;
  }

  .button-wrapper > div{
    flex: 1;
    width: 100%;
    margin-right: 5px;
  }

  .button-wrapper > input{
    width: 159px;
    height: 36px;
    padding: 0 10px;

    &::placeholder {
      text-align: right;
      font-size: 16px;
      font-weight: 500;
      color: #6d7278;
      opacity: 1;
      letter-spacing: 0.6px;
    }
  }

  #btn-send{
    margin-left: 5px;
    width: 181px;
    height: 36px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
  }

  .invitation-sent{
    margin-top: 50px;
    color: #093070;
    font-size: 21px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }

  @media (max-width: 992px) {
    .button-wrapper {
      width: 100%;
    }

  }
}
