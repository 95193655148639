#not_in_stock{
    .modal-content{
        width: 100%;
        max-width: 511px;
        .modal-header h4{
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: 0.85px;
            color: #212121;
        }
        .modal-container{
            .in-stock__header {
                display: flex;
                padding-left: 56px;
                align-items: flex-end;
                justify-content: space-between;
                margin: 10px 0px;
                p{
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.74px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.5);
                    padding: 0px 9px;
                    margin: 0px;
                }
            }
            .in-stock__variants{
                list-style: none;
                padding: 0px;
                max-height: 200px;
                overflow: auto;
                .stock-variant{
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                    padding: 12px 0px;
                    margin: 0px;
                    .stock-variant__image{
                        width: 56px;
                        height: 56px;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        align-self: flex-start;
                    }
                    .stock-variant__data{
                        flex: 1;
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        .stock-variant__info{
                            padding: 0px 9px;
                            display: flex;
                            flex-direction: column;
                            align-content: space-between;
                            justify-content: space-between;
                            min-height: 56px;
                            p{
                                margin: 0px;
                            }
                            .stock-variant__name{
                                font-size: 16px;
                                font-weight: 500;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: 0.85px;
                                color: rgba(0, 0, 0, 0.85);
                            }
                            .stock-variant__type{
                                font-size: 14px;
                                font-weight: 500;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: 0.74px;
                                color: rgba(0, 0, 0, 0.5);
                            }
                        }
                        .stock-variant__quantity{
                            margin: 0px;
                            padding: 0px 9px;
                            text-align: right;
                            font-size: 14px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: 0.74px;
                            color: rgba(0, 0, 0, 0.85);
                        }
                    }
                }
            }
        }
        .modal-footer{
            #cancel_not_in_stock{
                width: 156px;
            }
        }
        @media (max-width: 511px) {
            .modal-header h4{
                font-size: 14px;
                text-align: center;
            }
            .modal-container{
                .in-stock__header {
                    padding-left: 0px;
                }
                .in-stock__variants{
                    .stock-variant{
                        flex-direction: column;
                        .stock-variant__image{
                            width: 36px;
                            height: 36px;
                            margin: 0px 9px;
                            margin-bottom: 20px;
                        }
                        .stock-variant__data{
                            width: 100%;
                            .stock-variant__info{
                                height: auto;
                                .stock-variant__name{
                                    font-size: 14px;
                                }
                                .stock-variant__type{
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
            .modal-footer{
                justify-content: center;
            }
        }
    }
}