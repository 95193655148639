.order-details {
  &__header {
    margin: $global-margin 0 $global-margin * 2;
    text-transform: capitalize;
    .label:before {
      display: none;
    }
  }
  &__addresses {
    margin-top: $global-margin * 2;
  }
  &__product {
    align-items: center;
    .col-3 {
      margin-top: $global-margin / 2;
    }
    &__description {
      a {
        display: flex;
        align-items: center;
        transition: 0.3s;
        &:hover {
          color: $skull-gray;
        }
        p {
          word-break: break-word;
          white-space: normal !important;
        }
      }
      img {
        vertical-align: top;
        margin-right: $global-margin;
        width: 60px;
        height: 60px;
        @media (max-width: 600px) {
          width: 40px;
          height: 40px;
        }
      }
      @media (max-width: 600px) {
        p {
          font-size: 16px;
        }
      }
    }
  }
  &__table {
    width: 100%;
    &__quantity-web {
      display: block;
      @media (max-width: 600px) {
        display: none;
      }
    }
    &__quantity-mobile {
      display: none;
      @media (max-width: 600px) {
        display: block;
      }
    }
    thead {
      tr {
        th {
          p {
            margin-bottom: 0px;
            color: #828282;
            font-weight: 400;
            white-space: nowrap;
            text-transform: uppercase;
          }
        }
        th:last-child {
          padding-left: 10px;
        }
        th:first-child {
          padding-right: 10px;
        }
      }
      @media (max-width: 600px) {
        display: none;
      }
    }
    tbody {
      tr {
        td {
          padding: 8px 0px;
          border-bottom: 1px solid #d3d1d0;
          border-top: 1px solid #d3d1d0;
          p {
            margin-bottom: 0px;
            white-space: nowrap;
            @media (max-width: 600px) {
              font-size: 14px;
            }
          }
        }
        td:last-child {
          padding-left: 10px;
        }
        td:first-child {
          padding-right: 10px;
        }
      }
      tr:last-child td {
        border-bottom-color: $skull-gray;
      }
    }
  }
  &__last-row {
    border-bottom-color: $skull-gray;
  }
  &__table-footer {
    @include media-breakpoint-up(sm) {
      padding-left: calc(60px + #{$global-padding} * 2);
    }
  }
  &__total {
    padding-top: $global-padding;
    color: $skull-gray;
  }
  &__back-buy {
    margin: 10px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .account-back__buy,
    .account-back__buy:hover {
      width: 185px;
      height: 40px;
    }
    @media (max-width: 600px) {
      justify-content: center;
      .account-back__buy,
      .account-back__buy:hover {
        width: 96%;
        max-width: 308px;
        height: 40px;
        font-size: 16px;
      }
    }
  }
}

.order-balance__details {
  margin-top: 40px;
  p {
    margin-bottom: 0px;
    color: #828282;
    font-size: 16px;
    text-align: left;
  }
  .order-balance__detail {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    .order-balance__value {
      color: #333333;
      text-align: right;
      &.order-balance__discount {
        color: #f2994a;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .order-balance__discount-title {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-weight: bold;
    }
    hr {
      flex: 1;
      margin-left: 10px;
    }
  }
  .order-balance__discount-free {
    background-color: #f2994a;
    color: #f2f2f2;
    font-size: 12px;
    border-radius: 4px;
    width: 60px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    margin-right: 12px;
    text-transform: uppercase;
    &.not-margin {
      margin-right: 0px;
    }
  }
}
.order-notes {
  margin-top: 2 * $global-margin;
  > p {
    white-space: pre-wrap;
  }
}
