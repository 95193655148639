// Razorpay does not support custom button classes
// so we need to reimplement the bootstrap button.
.razorpay-payment-button {
  cursor: pointer;
  @include button-variant($primary, $primary);
  @include button-size(
      $btn-padding-y, $btn-padding-x,
      $font-size-base, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);
}
.payment-form__actions{
  display: flex;
  .payment-return{
    margin-right: 10px;
    @media (max-width: 600px) {
      width: 290px;
      margin-right: 0px;
    }
  }
}
.payment-form__submit{
	margin-top: 20px;
}
.payment-dates{
  display: flex;
  .payment-input__date{
    margin-bottom: 0px;
    input, label{
      margin-bottom: 0px;
    }
  }
}