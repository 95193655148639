.product-card {
  padding: 20px 6px;
  &__content {
    width: 190px;
    height: 365px;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12),
      0 1px 1px 0 rgba(0, 0, 0, 0.14);
    background-color: #ffffff;
    padding: 12px 9px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    transition: box-shadow 300ms ease-in-out;
    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 2px 1px 0 rgba(0, 0, 0, 0),
        0 8px 8px 4px rgba(0, 0, 0, 0.08);
    }
    .shopping-list__content {
      .shopping-list__icon {
        padding: 0px;
        border: 0px;
        position: absolute;
        right: 0;
        top: 0;
        background-color: transparent;
        margin: 4px;
        cursor: pointer;
        svg {
          width: 24px;
          height: 24px;
          fill: #e0e0e0;
          path {
            stroke: #e0e0e0;
          }
        }
        &.on {
          svg {
            fill: #024638;
            path {
              stroke: #024638;
            }
          }
        }
      }
    }
    .product-card__description {
      height: calc(100% - 164px);
      display: flex;
      flex-direction: column;
      flex: 1;
      &:hover {
        text-decoration: none;
      }
      .product-card__image {
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .badge-discount {
          background-color: #ff0f00;
          padding: 5px 8px;
          color: white;
          border-radius: 12px;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 12px;
          font-weight: bold;
        }
        img {
          height: 152px;
          width: 152px;
          object-fit: contain;
        }
      }
      .product-card__info {
        .product-card__name {
          font-size: 14px;
          color: #333333;
          height: 52px;
          margin-bottom: 0px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }
        .product-card__price {
          font-size: 16px;
          font-weight: bold;
          color: #ff4949;
          margin-top: 10px;
          margin-bottom: 0px;
          display: flex;
          align-items: flex-end;
          &.discount {
            color: #ff4949 !important;
          }
          .product-card__discount {
            font-size: 12px;
            color: #828282;
            margin-left: 10px;
            text-decoration: line-through;
          }
        }
      }
    }
    .product-card__picker {
      margin-top: 10px;
      display: flex;
      align-items: flex-end;
      width: 100%;
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    max-width: 450px;
    padding: 8px 0px;
    &__content {
      width: 100%;
      height: 230px;
      .product-card__description {
        flex-direction: row;
        .product-card__image {
          margin-right: 10px;
          margin-bottom: 0px;
          position: relative;
          .badge-discount {
            background-color: #ff0f00;
            padding: 5px 8px;
            color: white;
            border-radius: 12px;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 12px;
            font-weight: bold;
          }
          img {
            height: 135px;
            width: 135px;
          }
        }
        .product-card__info {
          flex: 1;
          padding-right: 18px;
          .product-card__name {
            height: auto;
            margin-bottom: 10px;
          }
          .product-card__price {
            flex-direction: column;
            align-items: flex-start;
            .product-card__discount {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
}
