.variant-picker {
  margin-bottom: $global-margin;
  .btn-group {
    display: inline-block;
  }
  &__label {
    margin-bottom: $global-margin;

  }
  &__options {
    display: block;
  }
  &__option {
    margin-bottom: 0;
    margin-bottom: $global-margin / 4;
    border-color: $gray;
    float: left;
    &.active {
      background: $justo-green !important;
      color: white !important;
    }
    input {
      display: none;
    }
  }
}
